import imask from 'imask';

export function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]/g, '');

    // Verifica se o CNPJ não consiste apenas em zeros
    if (/^0+$/.test(cnpj) || cnpj.length !== 14) {
        return false;
    }

    const pesos1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const pesos2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

    const calcularDigito = (cnpj, pesos) => {
        let soma = 0;
        for (let i = 0; i < pesos.length; i++) {
            soma += parseInt(cnpj.charAt(i)) * pesos[i];
        }
        const resto = soma % 11;
        return resto < 2 ? 0 : 11 - resto;
    };

    const digito1 = calcularDigito(cnpj.slice(0, 12), pesos1);
    const digito2 = calcularDigito(cnpj.slice(0, 13), pesos2);

    return (
        parseInt(cnpj.charAt(12)) === digito1 &&
        parseInt(cnpj.charAt(13)) === digito2
    );
}

export function validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]/g, '');

    // Verifica se o CPF não consiste apenas em zeros
    if (/^0+$/.test(cpf) || cpf.length !== 11) {
        return false;
    }

    if (/^(\d)\1{10}$/.test(cpf)) {
        return false;
    }

    let soma = 0;
    for (let i = 0; i < 9; i++) {
        soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let digitoVerificador1 = 11 - (soma % 11);
    if (digitoVerificador1 > 9) {
        digitoVerificador1 = 0;
    }

    soma = 0;
    for (let i = 0; i < 10; i++) {
        soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    let digitoVerificador2 = 11 - (soma % 11);
    if (digitoVerificador2 > 9) {
        digitoVerificador2 = 0;
    }

    return parseInt(cpf.charAt(9)) === digitoVerificador1 && parseInt(cpf.charAt(10)) === digitoVerificador2;
}

export function inicializarValidacao(containerId) {
    const container = document.querySelector(containerId);

    if (!container) return;

    const cnpjField = container.querySelector('[name="cf_cnpj"]');
    const cpfField = container.querySelector('[name="cf_cpf"]');

    if (cpfField) imask(cpfField, { mask: '000.000.000-00' });
    if (cnpjField) imask(cnpjField, { mask: '00.000.000/0000-00' });

    const labelErrorElement = document.createElement('label');
    labelErrorElement.setAttribute('class', 'error');
    labelErrorElement.setAttribute('generated', 'true');
    labelErrorElement.setAttribute('id', 'error_cpf_cnpj');
    labelErrorElement.innerText = 'Campo inválido';

    cpfField && cpfField.parentNode.insertBefore(labelErrorElement.cloneNode(), cpfField.nextSibling);
    cnpjField && cnpjField.parentNode.insertBefore(labelErrorElement.cloneNode(), cnpjField.nextSibling);

    const validateField = (field, validator) => {
        field.addEventListener('input', () => {
            const sanitizedValue = field.value.replace(/\W/g, '');

            if (validator(sanitizedValue)) {
                field.classList.remove('error');
                field.nextElementSibling.classList.remove('display');
                field.nextElementSibling.innerHTML = '';
                document.querySelector('.rd-button').removeAttribute('disabled');
            } else {
                field.classList.add('error');
                field.nextElementSibling.classList.add('display');
                field.nextElementSibling.innerHTML = 'Campo Inválido';
                document.querySelector('.rd-button').setAttribute('disabled', 'true');
            }
        });
    };

    cpfField && validateField(cpfField, validarCPF);
    cnpjField && validateField(cnpjField, validarCNPJ);
}
