<template>
    <div class="home-btn-gmzmoke">
       
        <h2 class="mb-4 text-center ">O que você deseja?</h2>
        <div class="buttons btn-whats-gmzmoke">
            
            <button class="btn btn-success w-100 mb-2" @click="exibirTela('orcamento')">
                Orçamento
            </button>

            <button class="btn btn-success w-100 mb-2" @click="exibirTela('credenciamento')">
                Rede Credenciada
            </button>

        </div>

    </div>
</template>

<script>
export default {
    methods: {
        exibirTela(tela) {
            this.$emit('mudarTela', tela);
        }
    }
}
</script>