<template>
    <div>
        <div :id="id" class="rd-station-form-container" :class="{ loaded }">
            <div class="loader" v-if="!loaded">
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
    </div>
</template>

<script>
import { inicializarValidacao } from './../assets/CPF_CNPJ_helpers';
export default {
    props: [ 'id', 'code' ],
    data() {
        return {
            counter: 0,
            loaded: false
        }
    },
    mounted() {
        let form = new window.RDStationForms(this.id, this.code);
        form.createForm();

        this.waitForFormToLoad();
    },
    methods: {
        waitForFormToLoad: async function() {

            while (this.counter < 60 ) {
                this.counter++;

                const formElement = document.querySelector(`#${this.id}`).querySelector(`form`);
                if (formElement) {
                    inicializarValidacao(`#${this.id}`);
                    break;
                }

                await new Promise((resolve) => setTimeout(resolve, 50));
            }

            this.loaded = true;
        }
    }
}
</script>

<style>

.lds-ring,.lds-ring div{box-sizing:border-box}.lds-ring{color:#13653f;display:inline-block;position:relative;width:80px;height:80px}.lds-ring div{display:block;position:absolute;width:64px;height:64px;margin:8px;border:8px solid currentColor;border-radius:50%;animation:1.2s cubic-bezier(.5,0,.5,1) infinite lds-ring;border-color:currentColor transparent transparent}.lds-ring div:first-child{animation-delay:-.45s}.lds-ring div:nth-child(2){animation-delay:-.3s}.lds-ring div:nth-child(3){animation-delay:-.15s}@keyframes lds-ring{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}
.rd-station-form-container .loader {
    margin: auto;
    text-align: center;
}
.rd-station-form-container.loaded .loader { display: none }
.rd-station-form-container .loader:has(+ section) { display: none }

.rd-station-form-container #rd-row-lusqy548 .bricks--column > div,
.rd-station-form-container #rdstation-bricks-embeddable-form-bricks-component-6MS1c-pdfweYDVPCNlqlWA .bricks-form { padding: 0 !important }
.rd-station-form-container #rd-button-lusqy54f {
    background: #198754;
    border-color: #198754;
    font-size: 1rem;
    font-weight: normal;
    padding: .5rem .375rem;
    line-height: 1.5;
    min-height: 0;
    height: auto;
    border-radius: .4rem;
}
.rd-station-form-container #rdstation-bricks-embeddable-form-bricks-component-6MS1c-pdfweYDVPCNlqlWA .bricks-form__fieldset {
    margin-left: 0 !important;
    margin-right: 0 !important
}

.btn-whats-gmzmoke .btn-success {
    width: 100% !important;
    background-color: #198754;
    align-items: center;
    border-radius: .5rem;
    box-shadow: 0 2px 5px -1px #000;
    display: flex;
    justify-content: center;
    min-height: 2.5rem;
    transition: all .3s;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    margin-bottom: 10px;
    color: #fff;
    padding: 15px;
}

.home-btn-gmzmoke h2 {
    text-transform: uppercase;
    font-family: sans-serif;
    color: #198754;
    font-weight: 700;
}
</style>