<template>
    <FormRDStation id="f-botao-wpp-credenciamento-46457a5e1015d2618edf" code="UA-189218672-1"></FormRDStation>
</template>

<script>
import FormRDStation from '@/components/FormRDStation';
export default {
    components: {
        FormRDStation
    }
}
</script>