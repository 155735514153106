<template>
  <div>
    <FloatingButton @clicked="toggleModal"></FloatingButton>

    <ModalComponent v-if="showModal" @fecharModal="toggleModal"></ModalComponent>
    
  </div>
</template>

<script>
import FloatingButton from './components/FloatingButton.vue';
import ModalComponent from './components/ModalComponent.vue';

export default {
  name: 'App',
  components: {
    FloatingButton,
    ModalComponent
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal
    }
  },  
  data() {
    return {
      showModal: false
    }
  }
}
</script>


<style scoped>

</style>