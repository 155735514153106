<template>
    <FormRDStation id="f-botao-wpp-orcamento-2215f78c8e6d1e23fbab" code="UA-189218672-1"></FormRDStation>
</template>

<script>
import FormRDStation from '@/components/FormRDStation';
export default {
    components: {
        FormRDStation
    }
}
</script>