import { createApp } from 'vue'
import App from './App.vue'

// mount point
const container = document.createElement('div');
container.setAttribute('id', 'gmz-whatsapp-widget');
document.body.appendChild(container);

/** Carregar script RD Station */
const rd = document.createElement('script');
rd.setAttribute('src', 'https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js');

document.head.appendChild(rd);

createApp(App).mount(container); // garantir que app possa ser criado sem adicionar nada além do script
//createApp(App).mount('#gmz-widget')